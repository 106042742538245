import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./Aone.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Aone1 from "../../../../res/Aone/exposition/aone1.jpg"
import Aone2 from "../../../../res/Aone/exposition/aone2.jpg"
import Aone3 from "../../../../res/Aone/exposition/aone3.jpg"
import Aone4 from "../../../../res/Aone/exposition/aone4.jpg"
import Aone5 from "../../../../res/Aone/exposition/aone5.jpg"
import Aone6 from "../../../../res/Aone/exposition/aone6.jpg"
import Aone7 from "../../../../res/Aone/exposition/aone7.jpg"
import Aone8 from "../../../../res/Aone/exposition/aone8.jpg"
import Aone9 from "../../../../res/Aone/exposition/aone9.jpg"
import Aone10 from "../../../../res/Aone/exposition/aone10.jpg"
import Aone11 from "../../../../res/Aone/exposition/aone11.jpg"
import Aone12 from "../../../../res/Aone/exposition/aone12.jpg"
import Aone13 from "../../../../res/Aone/exposition/aone13.jpg"
import Tile from "./../../../../res/Aone/exposition/aone-tile.png"


const infos ={
  photos: [
    { src: Aone2, name: "Aone" },
    { src: Aone8, name: "Aone" },
    { src: Aone9, name: "Aone" },
  ],
};

const Aone = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
        <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="aone"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>RETROSPECTIVE<h2 style={{paddingLeft: "16px"}}>AONE - Marseille - 2020</h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="aone"/>
        <br />
        <p>
        Admiré, respecté et considéré comme un artiste majeur et incontournable du mouvement, AONE a contribué - grâce à ses créations sauvages, philosophiques, mystiques & spirituelles - au rayonnement de cette culture, bien au delà des frontières du South Bronx. Né dans le ghetto, son apprentissage se fît dans la rue et dans le métro. C’est en autodidacte qu’il va ainsi conquérir le monde de l’art. A la fin des années 70, le graffiti n’est alors qu’un geyser impétueux qui ne demande qu’à exploser de cette scène underground, dans lequel la majorité des galéristes, curateurs et journalistes, semble vouloir le cantonner. Au rythme du hip hop et du breakdance, il y avait alors toute une génération d’artistes, adolescents fougueux, qui souhaitaient montrer ce que leur art, non pas pouvait être, mais était. AONE en faisait parti, faisant tournoyer ses bombes dans une danse énergique et contagieuse.
            L’«Expressionnism aérosol» reflète ainsi la recherche d'une symbiose entre sa culture américaine et ses origines africaines, un parcours initiatique empruntant les chemins d’une philosophie prônant l’unité (des peuples) et la tolérance, à travers sa propre mythologie (forcement urbaine); Une quête d’absolu et d’idéal qui se retrouvent dans ses créations au style tout aussi énigmatique que magique.
          </p>
          <br />
        </div>
      </div>
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default Aone;
