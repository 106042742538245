// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "Aone-module--CarrouselWrapper2--nAwxf";
export var DescriptionWrapper = "Aone-module--DescriptionWrapper--4r4rR";
export var DownloaderWrapper = "Aone-module--DownloaderWrapper--KTxMk";
export var EventWrapper = "Aone-module--EventWrapper--58fe5";
export var ImageWrapper = "Aone-module--ImageWrapper--oluYs";
export var ImagesWrapper = "Aone-module--ImagesWrapper--FEf0V";
export var MobileTile = "Aone-module--MobileTile--HLala";
export var PdpWrapper = "Aone-module--PdpWrapper--zmaI6";
export var PhotosWrapper = "Aone-module--PhotosWrapper--htocb";
export var TitleWrapper = "Aone-module--TitleWrapper--AhdhU";
export var Wrapper = "Aone-module--Wrapper--8qzmi";